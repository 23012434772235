<template>
  <div>
    <page-title :heading="$t('settings.lang_externalApps')" :subheading="$t('generic.lang_edit')" :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <EditExternalAPPSComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import EditExternalAPPSComponent
  from "../../../../components/settings/moduleSettings/externalapss/EditExternalAPPSComponent";
export default {
  components: {
    EditExternalAPPSComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>